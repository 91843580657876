import React from 'react';
import {graphql} from 'gatsby';

import {useLocale} from '@app/hooks';

import {Breadcrumb, Layout, Seo, Share} from '@app/components';

const AboutUs: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'pages.AboutUs',
  };
  const {t} = useLocale();

  return (
    <Layout>
      <Breadcrumb title={t('labels.metaTitle', SCOPE_OPTIONS)} />
    </Layout>
  );
};

export default AboutUs;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
